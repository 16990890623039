import { EntityBase, Input, Var } from "addeus-common-library/stores/firestore/index";
import { Frame } from "./frame";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export enum Status {
    idle = "idle",
    playing = "playing",
    paused = "paused",
}

export enum HookType {
    default = "default",
    manual = "manual",
    whenRaceStart = "whenRaceStart",
    whenRaceEnd = "whenRaceEnd",
    whenKartAssociated = "whenKartAssociated",
    whenRacePrepare = "whenRacePrepare",
}

export class Hook extends EntityBase {
    @Var(HookType)
    @Input("select", {
        options: HookType,
        required: true,
    })
    type: HookType = HookType.manual;
}

export class Scenario extends EntityBase {
    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(Array.of(Frame))
    frames?: Frame[] = EntityArray();

    @Var(String)
    status: Status = Status.idle;

    @Var(Array.of(Hook))
    hooks?: Hook[] = EntityArray();

    @Var(Boolean)
    @Input("checkbox")
    loopable: boolean = false;

    toString() {
        return `${this.name}${
            this.frames?.length !== 0 ? " (" + this.frames?.join(", ") + ")" : ""
        }`;
    }
}
