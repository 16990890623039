import { acceptHMRUpdate, defineStore } from "pinia";
import { useEmployeeSession } from "../employeeSession";
import { ref, onValue, runTransaction, set } from "firebase/database";
import { useFirebase } from "addeus-common-library/stores/firebase";
import { ref as vueRef, computed, watch } from "vue";
import { SortableDriver, sortBestDriverLastDays } from "../../algorithm";
import { RankingPeriod } from "../../models/race";
import { type Driver } from "../../models/race";
import { cloneDeep } from "lodash";

export const usePeriodRanking = defineStore("periodRanking", () => {
    const employeeSession = useEmployeeSession();
    const firebase = useFirebase();

    const currentRanking = vueRef<SortableDriver[] | undefined>(undefined);

    const root = computed(() => `rankings/${employeeSession.user?.owner?.$getID()}`);
    const path = computed(() => `${root.value}/periodRanking`);
    let periodRankingRef = ref(firebase.database, path.value);
    let unsub;
    watch(
        root,
        () => {
            if (unsub !== undefined) {
                unsub();
            }
            periodRankingRef = ref(firebase.database, path.value);
            unsub = onValue(periodRankingRef, (snap) => {
                const value = snap.val() as Array<SortableDriver> | null;
                currentRanking.value = value ?? [];
            });
        },
        { immediate: true },
    );

    function sortableDriverMatch(a: SortableDriver, b: SortableDriver): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    async function updatePeriodRanking(
        drivers: Driver[],
        nbDays: number = 7,
        nbDrivers: number = 15,
    ) {
        const sortableDrivers: SortableDriver[] = drivers.map((driver) => {
            const result = new SortableDriver(
                driver.nickName,
                driver.getBestLapTime(true),
            );
            return result;
        });
        const clone = cloneDeep(currentRanking.value) ?? [];
        clone?.push(...sortableDrivers);
        const sortedDrivers = sortBestDriverLastDays(clone, nbDays, nbDrivers);
        const toRemove = currentRanking.value?.filter((driver) => {
            return (
                sortedDrivers.find((d) => sortableDriverMatch(driver, d)) === undefined
            );
        });
        const toAdd = sortedDrivers.filter((driver) => {
            return (
                currentRanking.value?.find((d) => sortableDriverMatch(driver, d)) ===
                undefined
            );
        });
        await runTransaction(
            periodRankingRef,
            (data: SortableDriver[] | null) => {
                const drivers = data ?? [];
                for (const remove of toRemove ?? []) {
                    const index = drivers.findIndex((d) =>
                        sortableDriverMatch(remove, d),
                    );
                    drivers.splice(index, 1);
                }
                drivers.push(...toAdd);
                return drivers;
            },
            { applyLocally: false },
        );
    }

    const updateRanking = async (drivers: Driver[], rankings: RankingPeriod[] = []) => {
        if (rankings.length === 0) {
            return;
        }
        if (rankings.includes(RankingPeriod.rolling)) {
            await updatePeriodRanking(
                drivers,
                employeeSession.user?.owner?.numberOfRankingDays,
                employeeSession.user?.owner?.numberOfRankedDrivers,
            );
        }
    };

    return {
        updateRanking,
        currentRanking,
    };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePeriodRanking, import.meta.hot));
}
