import { EntityBase, Input, Var } from "addeus-common-library/stores/firestore/index";

export enum FrameTypes {
    image = "image",
    next = "next",
    racing = "racing",
    racingLarge = "racingLarge",
    past = "past",
    ranking = "ranking",
    prepare = "prepare",
}

export class Frame extends EntityBase {
    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(String)
    @Input("select", { options: FrameTypes })
    type?: FrameTypes = FrameTypes.image;

    @Var(Number)
    @Input("number", { required: true, min: 1 })
    duration?: number = 60;

    @Var(String)
    @Input("file", { required: true, accepts: ["image/*", "video/*"] })
    file?: string;

    toString() {
        return this.name;
    }
}
